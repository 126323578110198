import React, { useState } from 'react'
import { connect } from "react-redux"
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { AppBar, Toolbar, IconButton, Button, MenuItem, Menu } from '@mui/material'

import {
  AccountCircle,
  ArrowDropDown as ArrowDropDownIcon,
  MenuRounded as MenuRoundedIcon
} from '@mui/icons-material'

import MenuVertical from '../Menu/Menu'
import { logout } from '../../../state/actions'
import Logo from '../../../common/components/Svgs/Logo'
import './Header.sass'

function Header(props) {

  const {
    logoSrc,
    firstName,
    logout
  } = props

  const [anchorEl, setAnchorEl] = React.useState(null)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const handleMenu = event => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const [open, setOpen] = useState(false);

  const handleOption = option => {
    if (option === 'logout') logout(navigate)
    else if (option === 'information') navigate('/information')
    else if (option === 'settings') navigate('/settings')
  }

  const StyledMenu = styled(props => (
    <Menu
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      {...props}
    />
  ))(() => ({
    '& .MuiList-root ': {
      padding: '0px'
    },
    '& .MuiPaper-root': {
      borderRadius: '0 0 4px 4px',
      minWidth: 229,
      color: 'black',
      background: '#F9F9F9',
      boxShadow: '0px 0px 6px rgba(146, 146, 146, 0.5)'
    },
    '& .MuiMenuItem-root:hover': {
      background: '#D8DFF5'
    }
  }));

  return (
    <AppBar position="static" className="header">
      <Toolbar className="header-toolbar">
        <IconButton
          size="large"
          edge="start"
          color="white"
          aria-label="menu"
          onClick={() => setOpen(!open)}
        >
          <MenuRoundedIcon />
        </IconButton>


        <Button variant="text" onClick={() => navigate('/')}>
          <div className='logo-img-wrapper'>
            {logoSrc ?
              <img
                src={logoSrc}
                alt="logo"
                className='logo-img'
              />
              :
              <Logo className='logo-img' />
            }
          </div>
        </Button>
        <p>| Lobby </p>
        <div className='ml-auto'>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-app-bar"
            aria-haspopup="true"
            className="header-options"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
            <p className="username">
              {firstName}
            </p>
            <ArrowDropDownIcon />
          </IconButton>
          <StyledMenu
            id="menu-app-bar"
            keepMounted
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => handleOption('logout')}>{t('logout')}</MenuItem>
          </StyledMenu>
        </div>
      </Toolbar>
      <MenuVertical open={open} setOpen={setOpen} />
    </AppBar>
  )
}

const mapStateToProps = state => {
  return {
    firstName: state.profile.first_name,
    logoSrc: state.profile.logo
  }
}

const mapDispatchToProps = {
  logout
}


export default connect(mapStateToProps, mapDispatchToProps)(Header)