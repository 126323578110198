import { Types } from "../actionTypes"
import { api } from '../../api/api'

import {
  setLog,
  types as errorTypes
} from '../../common/utilities/LogError'


const _dispatch = dispatch => {
  return dispatch
}

const deviseAuth = (payload, baseURL) => {
  return function (dispatch) {
    return api.post('/auth/sign_in', payload, { baseURL })
      .then(({ data }) => {
        dispatch({
          type: Types.SET_AUTH,
          payload: data
        })
      })
  }
}

const setLoading = loading => {
  return function (dispatch) {
    dispatch({
      type: Types.SET_BACKDROP_LOADING,
      payload: { loading }
    })
  }
}

const defaultSuccess = r => {
  return function (dispatch) {
    setLoading(false)(dispatch)
    return r
  }
}

const defaultCatch = (error, navigate) => {
  return function (dispatch) {
    setLoading(false)

    if (error?.response?.status === 401) logout(navigate)(dispatch)
    else if (error.response) {
      setLog({
        title: error.response.status,
        type: errorTypes.API_ERROR,
        message: error.response.data.errors,
        api_url: error.response.config.url,
        api_method: error.response.config.method,
        api_params: error.response.config.data
      })
    } else setLog({ type: errorTypes.API_ERROR, message: error.message })
  }
}

const setSnackbar = payload => {
  return function (dispatch) {
    dispatch({
      type: Types.SET_SNACKBAR_DATA,
      payload
    })
  }
}

const setDomain = domain => {
  return function (dispatch) {
    dispatch({
      type: Types.SET_DOMAIN,
      payload: domain
    })
  }
}

const logout = navigate => {
  return function (dispatch) {
    dispatch({ type: Types.CLEAN_AUTH })
    navigate('/login')
  }
}

export {
  logout,
  _dispatch,
  defaultSuccess,
  defaultCatch,
  deviseAuth,
  setLoading,
  setSnackbar,
  setDomain
}