import axios from 'axios'

const getUrl = () => {
  return process.env.REACT_APP_STAGE === 'production' ?
    `https://api.parso.cr` :
    `https://apidev.parso.cr`
}

const getBaseUrl = () => `${getUrl()}/api`;

const
  api = axios.create()

api.interceptors.request.use(
  config => {
    config.headers['tenant'] = localStorage.getItem('domain') || 'public'

    let sessionTokens = localStorage.getItem('tokens')
    sessionTokens = JSON.parse(sessionTokens)
    if (sessionTokens['access-token']) {
      config.headers['access-token'] = sessionTokens['access-token']
      config.headers['client'] = sessionTokens['client']
      config.headers['uid'] = sessionTokens['uid']
    }
    config.baseURL = getBaseUrl()
    return config
  }
)

const doneHandler = response => {
  if (response?.headers['access-token']) {
    setTokens(response.headers)
  }
  return response
}

const errorHandler = error => {
  return Promise.reject(error)
}

api.interceptors.response.use(doneHandler, errorHandler)


const setTokens = tokens => localStorage.setItem('tokens', JSON.stringify(tokens))

const clearAuth = () => {
  localStorage.setItem('tokens', '{}')
  localStorage.setItem('domain', 'public')
}

export {
  api,
  getUrl,
  getBaseUrl,
  setTokens,
  clearAuth
}

