import React, { useRef, useCallback } from 'react'
import {
  Button,
  Stack,
  Dialog,
  DialogContent,
  IconButton,
  useMediaQuery,
  useTheme
} from '@mui/material'

import {
  Close as CloseIcon
} from '@mui/icons-material'

import Webcam from 'react-webcam'
import { defaultFunction } from "../../utilities/utilities"



function PhotoDialog(props) {
  const {
    open = false,
    setPhoto = defaultFunction,
    onClose = defaultFunction,
    photo = '',
  } = props


  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up('md'))

  const webcamRef = useRef(null)
  const capture = useCallback(
    () => {
      setPhoto(webcamRef.current.getScreenshot())
    },
    [setPhoto]
  )

  return (
    <Dialog
      className='visitor-dialog'
      fullScreen={!isLarge}
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth="sm">
      <DialogContent>
        <Stack gap={4}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <h1>Capturar Fotografía</h1>
            <IconButton
              aria-label="close"
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack gap={2} alignItems="center">
            {photo ?
              <Stack gap={2} alignItems="center">
                <div className="webcam-wrapper">
                  <img src={photo} alt='user' />
                </div>
                <h2>Coloca el rostro en círculo blanco.</h2>
                <Stack gap={2} direction="row" alignItems="center" justifyContent="space-between">
                  <Button
                    variant='outlined'
                    size='large'
                    onClick={() => setPhoto()}
                  >
                    Capturar nuevamente
                  </Button>
                  <Button
                    variant='contained'
                    size='large'
                    onClick={onClose}
                  >
                    Guardar
                  </Button>
                </Stack>
              </Stack>
              :
              <>
                <div className='webcam-wrapper'>
                  <Webcam
                    ref={webcamRef}
                    audio={false}
                    width={370}
                    height={370}
                    screenshotFormat="image/jpeg"
                  />
                </div>
                <Stack gap={2}>
                  <h2>Coloca el rostro en círculo blanco.</h2>
                  <Button variant='contained' size='large' onClick={capture}>
                    Capturar
                  </Button>
                </Stack>
              </>
            }
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

export default PhotoDialog