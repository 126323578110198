import { Types } from '../actionTypes'
import { clearAuth } from '../../api/api'

const initialState = {
  id: -1,
  email: '',
  uid: '',
  name: '',
  first_name: '',
  last_name: '',
  building_id: -1,
  tenants: ["public"],
  companies: [],
  buildings: [],
  enabled_visit_photo: false,
  enabled_visit_qr: false,
  enabled_identification_photo: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_DOMAIN:
      const domain = action.payload
      state.domain = domain
      localStorage.setItem('domain', domain)
      return state
    case Types.SET_AUTH:
      const {
        id = -1,
        email = '',
        uid = '',
        name = '',
        first_name = '',
        last_name = '',
        building_id = -1,
        tenants = ['public'],
      } = action.payload.data

      const {
        enabled_visit_photo = false,
        enabled_visit_qr = false,
        enabled_identification_photo = false
      } = action.payload.tenant

      const goHome = state.goHome + 1

      return {
        ...state,
        id,
        email,
        uid,
        name,
        first_name,
        last_name,
        building_id,
        tenants,
        goHome,
        enabled_visit_photo,
        enabled_visit_qr,
        enabled_identification_photo
      }
    case Types.CLEAN_AUTH:
      clearAuth()
      return initialState
    case Types.SET_COMPANIES:
      return {
        ...state,
        companies: action.payload
      }
    case Types.SET_BUILDINGS:
      return {
        ...state,
        buildings: action.payload
      }
    case Types.SET_BUILDING:
      return {
        ...state,
        building_id: action.payload
      }
    default:
      return state
  }
}

export default reducer;