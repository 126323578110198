import React from 'react'

import {
  TextField,
  MenuItem,
  Stack,
  InputAdornment,
  useMediaQuery,
} from '@mui/material'

import { Search as SearchIcon } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useTheme } from '@emotion/react'

import { defaultFunction } from "../../utilities/utilities"
import MiniIsotype from '../Svgs/MiniIsoType'
import { Types } from '../../../state/actionTypes'
import { _dispatch as dispatch, defaultSuccess, defaultCatch, setLoading } from '../../../state/actions'

import './VisitorsFilter.sass'
import { api } from '../../../api/api'

function VisitorsFilter(props) {
  const {
    setLoading,
    dispatch,
    defaultSuccess,
    defaultCatch,
    building_id = -1,
    buildings = [],
    prefix = '',
    setPrefix = defaultFunction
  } = props

  const { t } = useTranslation()
  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'))

  const onChangeBuilding = building_id => {
    const payload = { user: { building_id } }
    setLoading(true)
    api.post('/profile', payload)
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        dispatch({
          type: Types.SET_BUILDING,
          payload: building_id
        })
      })
      .catch(defaultCatch)
  }

  return (
    <Stack
      className='VisitorsFilter'
      direction="row"
      spacing={3}
      alignItems="center"
    >
      {isLarge && <h1>Visitas ingresadas</h1>}
      <Stack
        className='site-wrapper'
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        {isLarge && <label>Estás viendo los registros de:</label>}
        <TextField
          select
          fullWidth
          name="type"
          value={building_id}
          size="small"
          onChange={e => onChangeBuilding(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MiniIsotype width="30px" />
              </InputAdornment>
            ),
          }}
        >
          {buildings.map(b =>
            <MenuItem key={b.id} value={b.id}>{b.name}</MenuItem>
          )}
        </TextField>
      </Stack>
      <TextField
        value={prefix}
        placeholder={t('Search')}
        onChange={e => setPrefix(e.target.value)}
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  )
}

const mapStateToProps = state => {
  return {
    buildings: state.profile.buildings,
    building_id: state.profile.building_id
  }
}

const mapDispatchToProps = {
  dispatch,
  defaultSuccess,
  defaultCatch,
  setLoading
}

export default connect(mapStateToProps, mapDispatchToProps)(VisitorsFilter)