import { createTheme } from '@mui/material/styles'

const defaultPrimaryMain = "#6C85D3"
const defaultSecundaryMain = "#50629C"
const defaultTertiaryMain = "#EEF2FF"

const themeGenerator = params => {
  document.documentElement.style.setProperty('--primary', defaultPrimaryMain)
  document.documentElement.style.setProperty('--secundary', defaultSecundaryMain)
  document.documentElement.style.setProperty('--tertiary', defaultTertiaryMain)

  return createTheme({
    typography: {
      button: { textTransform: 'none' }
    },
    palette: {
      primary: { main: defaultPrimaryMain },
      secondary: { main: defaultSecundaryMain },
      tertiary: { main: defaultTertiaryMain },
      error: { main: "#CF706F" },
      white: { main: "#FFF" }
    }
  })
}

export {
  themeGenerator
}