import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  ArrowDropDown as ArrowDropDownIcon
} from '@mui/icons-material'

import {
  Button,
  Menu,
  MenuItem,
} from '@mui/material'

function TableMenuGenerator(props) {

  const {
    row = {}
  } = props

  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onShowDetail = row => {
    setAnchorEl(null)
    props.onShowDetail(row)
  }

  const onEdit = row => {
    setAnchorEl(null)
    props.onShowEdit(row)
  }

  return (
    <div>
      <Button
        id={`basic-button-${row.id}`}
        aria-controls={open ? `basic-menu-${row.id}` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
      >
        {t('More')}
      </Button>
      <Menu
        id={`basic-menu-${row.id}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': `basic-button-${row.id}`,
        }}
      >
        <MenuItem onClick={() => onShowDetail(row)}>{t('show detail')}</MenuItem>
        <MenuItem onClick={() => onEdit(row)}>{t('edit')}</MenuItem>
      </Menu>
    </div>
  )
}

export default TableMenuGenerator