import PickersDay from '@mui/lab/PickersDay'

import {
  formatTime,
  isAllDay,
  removeTimezoneFromISO
} from './FormatDate'

import {
  styled,
  Switch,
  Box,
} from '@mui/material'

import {
  AirConditioner,
  Blackboard,
  Plug,
  Projector,
  VideoConference,
  Wifi,
  Monitor
} from '../components/Svgs/Amenities'

import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material';
import { Trans } from 'react-i18next';

const getCurrentBrowser = () => {
  var ua = navigator.userAgent
  var tem
  var M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || []
    return 'IE ' + (tem[1] || '')
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera')
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1])
  return M.join(' ');
}

const getPlace = item => {
  const {
    site = { name: '' },
    building = { name: '' },
    floor = { name: '' }
  } = item
  return `${site.name}, ${building.name}, ${floor.name}`
}

const getTime = (item, t) => {
  let {
    entry_time = '',
    exit_time = ''
  } = item

  entry_time = removeTimezoneFromISO(entry_time)
  exit_time = removeTimezoneFromISO(exit_time)

  if (isAllDay({ entry_time, exit_time })) return t('all day')
  return `${formatTime(entry_time)} - ${formatTime(exit_time)}`
}

const sortItems = (items = []) => {
  return items.sort((a, b) => {
    const _a = a.name.toLowerCase()
    const _b = b.name.toLowerCase()

    if (_a < _b) return -1
    else if (_a > _b) return 1
    return 0
  })
}

const getFullName = params => {
  const {
    first_name,
    last_name_one
  } = params

  return `${first_name} ${last_name_one}`
}

const getNoRowOverlay = () => {
  const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    textAlign: 'center',
    fontSize: '18px'
  }))

  return (
    <StyledGridOverlay>
      <InfoOutlinedIcon fontSize="large" />
      <Box sx={{ mt: 1 }}>
        <Trans i18nKey="No Rows">
          Oops, you still have no existing records. <br />Create a new registration and enjoy the benefits that Lobby has for you.
        </Trans>
      </Box>
    </StyledGridOverlay>
  )
}

const ParsoSwitch = styled(Switch)(({ theme, checked, leftlabel, rightlabel }) => ({
  padding: 0,
  width: 235,
  height: 40,
  fontSize: 13,
  '& .MuiSwitch-switchBase': {
    padding: 2
  },
  '& .MuiSwitch-track': {
    borderRadius: 20,
    backgroundColor: '#EFEFEF',
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 113,
      height: 32,
    },
    '&:before': {
      content: leftlabel,
      left: 12,
      marginTop: 10
    },
    '&:after': {
      content: rightlabel,
      right: 12,
      marginTop: 10
    }
  },
  '& .Mui-checked+.MuiSwitch-track': {
    backgroundColor: '#EFEFEF'
  },
  '& .Mui-checked': {
    transform: 'translateX(115px)'
  },
  '& .MuiSwitch-thumb': {
    padding: '10px 0 0 0',
    boxSizing: 'border-box',
    boxShadow: 'none',
    borderRadius: 20,
    background: 'white',
    width: 113,
    height: 32,
    margin: 2,
    '&:before, &:after': {
      content: '""',
      transform: 'translateY(-50%)',
      width: 113,
      height: 32,
      color: 'black'
    },
    '&:before': {
      content: checked ? rightlabel : leftlabel,
    },
  },
}));


const debounce = (func, wait, immediate) => {
  var timeout
  return function () {
    var context = this, args = arguments
    var later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    };
    var callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'selected'
})(({ theme, selected, unavailable }) => ({
  margin: '0 2px',
  borderTopLeftRadius: '50%',
  borderBottomLeftRadius: '50%',
  borderTopRightRadius: '50%',
  borderBottomRightRadius: '50%',
  ...(selected && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark
    }
  }),
  ...(unavailable && {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.error.dark
    }
  })
}));

const stopEvent = e => {
  if (e.preventDefault !== undefined) e.preventDefault()
  if (e.stopPropagation !== undefined) e.stopPropagation()
}

const getNumberToWeekDay = (n, t) => {
  if (n === 1) return t('Monday')
  if (n === 2) return t('Tuesday')
  if (n === 3) return t('Wednesday')
  if (n === 4) return t('Thursday')
  if (n === 5) return t('Friday')
  if (n === 6) return t('Saturday')
  if (n === 7) return t('Sunday')
  return ''
}

const getAmenitieImage = i => {
  if (i === 0) return <AirConditioner />
  if (i === 1) return <Blackboard />
  if (i === 2) return <Plug />
  if (i === 3) return <Projector />
  if (i === 4) return <VideoConference />
  if (i === 5) return <Wifi />
  return <Monitor />
}

const getCompanies = (companies = []) => {
  let uniqueIds = []
  return companies.filter(c => {
    if (uniqueIds[c.id]) {
      return false
    } else {
      uniqueIds[c.id] = c
      return true
    }
  })
}

const isHome = () => window.location.pathname === '/'

const defaultFunction = () => { }

export {
  isHome,
  defaultFunction,
  getNumberToWeekDay,
  getCurrentBrowser,
  getAmenitieImage,
  getFullName,
  getNoRowOverlay,
  CustomPickersDay,
  ParsoSwitch,
  getCompanies,
  stopEvent,
  sortItems,
  getPlace,
  debounce,
  getTime,
}