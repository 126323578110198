import React from 'react'
import { Stack, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import './Field.sass'


function Field(props) {
  const { t } = useTranslation()

  const {
    multiline,
    minRows,
    label,
    placeholder = t('write'),
    direction = 'row',
    alignItems = "center",
    value,
    children,
    disabled = false,
    sx = {},
    onChange = () => { }
  } = props

  return (
    <Stack
      className='Field'
      sx={sx}
      gap={2}
      direction={direction}
      justifyContent="space-between"
      alignItems={alignItems}
    >
      {label && <label>{label}</label>}
      <TextField
        disabled={disabled}
        size='small'
        multiline={multiline}
        minRows={minRows}
        fullWidth
        variant="filled"
        value={value}
        label={placeholder}
        onChange={onChange}
        autoComplete="off"
      />
      {children}
    </Stack>
  )
}

export default Field