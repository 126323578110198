import React, { useState } from 'react'
import Header from '../Header/Header'
import Menu from '../Menu/Menu'

import './Layout.sass'

function Layout(props) {
  const { children } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <Header />
      <div className='layout'>
        {children}
      </div>
      <Menu open={open} setOpen={setOpen} />
    </>
  );
}


export default Layout
