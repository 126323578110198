export const Types = {
  SET_DOMAIN: 'SET_DOMAIN',
  SET_SNACKBAR_TOGGLE: 'SET_SNACKBAR_TOGGLE',
  SET_SNACKBAR_DATA: 'SET_SNACKBAR_DATA',
  SET_BACKDROP_TOGGLE: 'SET_BACKDROP_TOGGLE',
  SET_BACKDROP_LOADING: 'SET_BACKDROP_LOADING',
  SET_AUTH: 'SET_AUTH',
  CLEAN_AUTH: 'CLEAN_AUTH',
  SET_IS_ONLINE: 'SET_IS_ONLINE',
  SET_LANGUAGE: 'SET_LANGUAGE',
  SET_LOGO: 'SET_LOGO',
  SET_COMPANIES: 'SET_COMPANIES',
  SET_BUILDINGS: 'SET_BUILDINGS',
  SET_BUILDING: 'SET_BUILDING'
}