import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress'

import Dialog from '@mui/material/Dialog'
import { DialogContent, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { connect } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import Isotype from '../../common/components/Svgs/Isotype'
import {
  deviseAuth, setLoading, setSnackbar, setDomain, defaultCatch, defaultSuccess
} from '../../state/actions'
import { api, getBaseUrl, clearAuth } from '../../api/api'
import { REGEX_EMAIL } from '../../variables'

import './Login.sass'

function Login(props) {
  const {
    loading,
    goHome,
    deviseAuth,
    setLoading,
    setSnackbar,
    setDomain,
    defaultCatch,
    defaultSuccess
  } = props

  const { t } = useTranslation()
  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up('md'))

  let navigate = useNavigate()
  const ref = useRef()

  const handleNavigate = useCallback(() => navigate('/load', { replace: true }), [navigate])


  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [open, setOpen] = useState(false)
  const [showPasswordField, setShowPasswordField] = useState(false)

  useEffect(() => {
    if (!ref.current) {
      ref.current = 1
      return
    }

    handleNavigate()
  }, [goHome, handleNavigate])

  const classicAuth = useCallback(baseUrl => {
    setShowPasswordField(true)
  }, [])

  const authType = useCallback(callback => {
    return api.post('users/tenants', { email })
      .then(({ data }) => {
        const { tenants = [] } = data
        const domain = tenants[0] || 'public'
        setDomain(domain)
        const baseUrl = getBaseUrl(domain)
        if (callback) return callback(baseUrl)
        return classicAuth(baseUrl)
      })
  }, [classicAuth, email, setDomain])

  const evalEmail = useCallback(() => {
    let error = ''

    if (
      email &&
      !REGEX_EMAIL.test(email)
    ) {
      error = t('invalid email')
    }

    setEmailError(error)
    return error
  }, [email, t])

  const handleTypeAuth = useCallback(() => {
    const emailError = evalEmail()
    if (emailError) return
    setLoading(true)
    authType()
      .finally(() => setLoading(false))
  }, [authType, evalEmail, setLoading])


  const handleEmail = useCallback(e => {
    const email = e.target.value
    setPassword('')
    setEmailError('')
    setPasswordError('')
    setShowPasswordField('')
    clearAuth()
    setEmail(email)
  }, [])

  const handlePassword = e => {
    setPasswordError('')
    setPassword(e.target.value)
  }

  const isDisabled = useCallback(
    () => loading || !(email && password),
    [email, loading, password])

  const isDisabledNext = useCallback(
    () => loading || !!emailError || !email,
    [email, emailError, loading]
  )


  const isDisabledRecover = () => !email
  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const loginCatch = useCallback(e => {
    if (e?.response?.status === 401) {
      if (Array.isArray(e?.response?.data?.errors))
        setPasswordError(t(e.response.data.errors[0]))
      else setPasswordError(t('password is incorrect'))
    } else if (e?.response?.message) {
      setPasswordError(e?.response?.message)
    } else if (e?.message) {
      setPasswordError(t(e?.message))
    } else setPasswordError(t('unknown error'))
  }, [t])

  const handleClassicAuth = useCallback(() => {
    setLoading(true)
    deviseAuth({ email, password })
      .catch(loginCatch)
      .finally(() => setLoading(false))
  }, [deviseAuth, email, loginCatch, password, setLoading])

  const emailProps = useMemo(() => {
    return ({
      className: 'cp-text-field',
      fullWidth: true,
      size: 'medium',
      autoComplete: 'on',
      variant: 'filled',
      label: t('enter your email'),
      type: 'email'
    })
  }, [t])

  const passwordProps = useMemo(() => {
    return ({
      className: 'cp-text-field',
      fullWidth: true,
      size: 'medium',
      autoFocus: true,
      variant: 'filled',
      label: t('enter your password')
    })
  }, [t])

  const handleKeyPress = useCallback(event => {
    if (event.key !== 'Enter') return
    if (isDisabled()) return
    handleClassicAuth()
  }, [handleClassicAuth, isDisabled])

  const handleEmailKeyPress = useCallback(event => {
    if (
      !email ||
      event.key !== 'Enter' ||
      loading ||
      showPasswordField
    ) return

    handleTypeAuth()
  }, [email, handleTypeAuth, loading, showPasswordField])

  const sendResetPasswordMail = useCallback(() => {
    const emailError = evalEmail()
    if (emailError) return

    setLoading(true)
    authType(() =>
      api.post('/password/reset', { email })
        .then(defaultSuccess)
        .then(() => {
          setSnackbar({
            open: true,
            message: t("we have sent you an email to x to change your password", { x: email }),
            severity: 'success',
            autoHideDuration: 2000
          })
          handleClose()
        })
        .catch(defaultCatch)
    )
  }, [authType, defaultCatch, defaultSuccess, email, evalEmail, handleClose, setLoading, setSnackbar, t])

  const forgetPasswordDialog = () => {
    return (
      <Dialog
        className="dialog-reset-password"
        fullScreen={!isLarge}
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="sm"
        aria-labelledby="responsive-dialog-title">
        <DialogContent>
          <div className="dialog-content-inner">
            <IconButton
              className="close-button"
              aria-label="close"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
            <Stack gap={3}>
              <h1>{t('reset your password')}</h1>
              <p>{t('We will send you an email to reset your password')}</p>
              <Stack gap={3}>
                <TextField
                  size="medium"
                  variant="filled"
                  label={t('enter your email')}
                  fullWidth={true}
                  value={email}
                  type="email"
                  error={!!emailError}
                  helperText={emailError}
                  onChange={handleEmail}
                  pattern=".+@globex\.com"
                  required
                />
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  onClick={sendResetPasswordMail}
                  disabled={isDisabledRecover()}>
                  {t('Send')}
                </Button>
              </Stack>
            </Stack>
          </div>
        </DialogContent>
      </Dialog>
    )
  }

  const getButtons = useCallback(() => {
    if (showPasswordField) return (
      <Button
        fullWidth
        size="large"
        variant="contained"
        disabled={isDisabled()}
        onClick={handleClassicAuth}>
        {loading ? (
          <CircularProgress
            size={24}
          />
        ) : t("continue")
        }
      </Button>
    )

    return (
      <Button
        fullWidth
        size="large"
        variant="contained"
        disabled={isDisabledNext()}
        onClick={handleTypeAuth}>
        {loading ? (
          <CircularProgress
            size={24}
          />
        ) : t("next")
        }
      </Button>
    )
  }, [handleClassicAuth, handleTypeAuth, isDisabled, isDisabledNext, loading, showPasswordField, t])


  const classicLoginForm = useCallback(() => {
    return (
      <div className="login-inner">
        <Isotype className="logo" />
        <h1 className='welcome-message'>
          {t('visitor registration')}
          <br />
          {t('we welcome you')}
        </h1>
        <Stack spacing={2}>
          {isLarge &&
            <label className="cp-label" htmlFor='email'>
              {t('email')}
            </label>
          }
          <TextField
            id="email"
            {...emailProps}
            value={email}
            error={!!emailError}
            helperText={emailError}
            onKeyPress={handleEmailKeyPress}
            onChange={handleEmail}
            pattern=".+@globex\.com"
            required
          />
          {showPasswordField && <>
            {isLarge && <span className="cp-span">
              <Button
                variant="text"
                onClick={() => setOpen(true)}>
                {t('did you forget your password')}
              </Button>
              <label
                className="cp-label"
                htmlFor='password'>
                {t('password')}
              </label>
            </span>}
            <TextField
              {...passwordProps}
              id="password"
              type={showPassword ? 'text' : 'password'}
              value={password}
              error={!!passwordError}
              helperText={passwordError}
              onChange={handlePassword}
              onKeyPress={handleKeyPress}
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
              }}
            />
            {!isLarge &&
              <Button
                variant="text"
                onClick={() => setOpen(true)}>
                {t('did you forget your password')}
              </Button>
            }
          </>}

          {getButtons()}

          <a
            className="privacy"
            href="https://www.parso.co/politica-de-privacidad-corporate-experience"
            target="_blank"
            rel="noreferrer">
            {t('privacy policy')}
          </a>
        </Stack>
      </div>
    )
  }, [isLarge, t, emailError, emailProps, email, handleEmailKeyPress, handleEmail, showPasswordField, passwordProps, showPassword, password, passwordError, handleKeyPress, getButtons])

  return (
    <div className="Login">
      {classicLoginForm()}
      {forgetPasswordDialog()}
    </div>
  )
}

const mapDispatchToProps = {
  deviseAuth,
  setLoading,
  setSnackbar,
  setDomain,
  defaultCatch,
  defaultSuccess
}

const mapStateToProps = state => {
  return {
    goHome: state.profile.goHome,
    loading: state.backdrop.loading
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)