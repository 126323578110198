import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { connect } from "react-redux"
import { Provider } from 'react-redux'
import { ThemeProvider } from '@mui/material/styles'
import { PersistGate } from 'redux-persist/integration/react'
import './fonts/AvenirLTStd-Medium.otf'
import 'normalize.css'
import './index.sass'
import './i18n'
import configureStore from './state/store'
import RoutingSystem from './routes'
import { themeGenerator } from './theme'
import GlobalSnackbar from './common/components/GlobalSnackbar/GlobalSnackbar'
import GlobalBackdrop from './common/components/GlobalBackdrop/GlobalBackdrop'
import NetworkDetector from './common/components/NetworkDetector/NetworkDetector'
import ErrorBoundary from './common/components/ErrorBoundary/ErrorBoundary'
import { IntercomProvider } from 'react-use-intercom'
import { Settings } from "luxon"
import i18n from 'i18next'

const { store, persistor } = configureStore();

const IntercomProviderGenerator = ({ children }) => {
  const ID = process.env.REACT_APP_INTERCOM_ID
  if (ID) return <IntercomProvider appId={ID} autoBoot>{children}</IntercomProvider>
  return children
}

const Bodier = ({ company = {}, lang }) => {

  useEffect(() => {
    if (!lang) return
    Settings.defaultLocale = lang
    i18n.changeLanguage(lang)
  }, [lang])

  const theme = themeGenerator({
    primaryMain: company?.brand_primary_color,
    secondaryMain: company?.brand_secondary_color,
    tertiaryMain: company?.brand_tertiary_color
  })

  return (
    <ThemeProvider theme={theme}>
      <PersistGate loading={null} persistor={persistor}>
        <RoutingSystem />
        <NetworkDetector />
        <GlobalSnackbar />
        <GlobalBackdrop />
      </PersistGate>
    </ThemeProvider>
  )
}

const mapStateToProps = state => {
  return {
    company: state.profile.company,
    lang: state.setting.lang
  }
}

const BodierWithState = connect(mapStateToProps)(Bodier)

ReactDOM.render(
  <React.StrictMode>
    <IntercomProviderGenerator>
      <ErrorBoundary>
        <Provider store={store}>
          <BodierWithState />
        </Provider>
      </ErrorBoundary>
    </IntercomProviderGenerator>
  </React.StrictMode>,
  document.getElementById('root')
)