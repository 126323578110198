import React, { useState } from 'react'
import { Button, CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { api } from '../../../api/api'
import { defaultFunction } from '../../utilities/utilities'
import { formatTime } from '../../utilities/FormatDate'


function TableButtonGenerator(props) {
  const {
    updateExiTime = defaultFunction
  } = props
  const { t } = useTranslation()
  const { row } = props
  const [loading, setLoading] = useState(false)


  const exitConfirmation = ({ id }) => {
    setLoading(true)

    api.put(`/visit/${id}`)
      .then(({ data }) => {
        updateExiTime(id, data.exit_time)
        setLoading(false)
      })
  }



  if (row.exit_time) return formatTime(row.exit_time)
  return (
    <Button
      sx={{ width: '180px' }}
      size='small'
      variant='contained'
      disabled={loading}
      onClick={() => exitConfirmation(row)}>
      {loading ?
        <CircularProgress size={20} /> :
        t('exit confirmation')
      }
    </Button>
  )
}


export default TableButtonGenerator