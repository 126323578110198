import { Dialog, DialogContent, IconButton, Stack, useMediaQuery, useTheme } from "@mui/material"
import { QrReader } from "react-qr-reader"

import {
  Close as CloseIcon
} from '@mui/icons-material'

import { defaultFunction } from "../../utilities/utilities"

function QRDialog(props) {

  const {
    open = false,
    onClose = defaultFunction,
    onChangeIdentification = defaultFunction,
  } = props

  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up('md'))

  const onQRResutl = (result, error) => {
    if (!!result) {
      onChangeIdentification(result?.text)
      onClose()
    }
  }

  return (
    <Dialog
      className='visitor-dialog'
      fullScreen={!isLarge}
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth="sm">
      <DialogContent>
        <Stack gap={4}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <h1>Escanear QR</h1>
            <IconButton
              aria-label="close"
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack gap={2} alignItems="center">
            <div style={{ width: '399px', background: 'black', border: 'solid 8px var(--primary)' }}>
              <QrReader
                onResult={onQRResutl}
                style={{ width: '100%', height: '100%', }}
              />
            </div>
            <h2>Coloca el QR en el rectángulo blanco.</h2>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}


export default QRDialog