import React, { useRef } from "react"
import { Button, Dialog, DialogContent, IconButton, Stack, useMediaQuery, useTheme } from "@mui/material"

import Webcam from 'react-webcam'
import {
  Close as CloseIcon
} from '@mui/icons-material'


import { defaultFunction } from "../../utilities/utilities"

function IDDialog(props) {

  const {
    open = false,
    onClose = defaultFunction,
    setPhoto = defaultFunction,
    photo = ''
  } = props


  const webcamIDRef = useRef(null)
  const captureIdentification = React.useCallback(
    () => {
      setPhoto(webcamIDRef.current.getScreenshot())
    },
    [setPhoto]
  )

  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Dialog
      className='visitor-dialog'
      fullScreen={!isLarge}
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth="sm">
      <DialogContent>
        <Stack gap={4}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <h1>Capturar fotografía</h1>
            <IconButton
              aria-label="close"
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack gap={2} alignItems="center">
            {photo ?
              <Stack gap={2} alignItems="center">
                <div className="webcam-wrapper-ID">
                  <img src={photo} alt='user' />
                </div>
                <h2>Coloca el rostro en círculo blanco.</h2>
                <Stack gap={2} direction="row" alignItems="center" justifyContent="space-between">
                  <Button
                    variant='outlined'
                    size='large'
                    onClick={() => setPhoto('')}
                  >
                    Capturar nuevamente
                  </Button>
                  <Button
                    variant='contained'
                    size='large'
                    onClick={onClose}
                  >
                    Guardar
                  </Button>
                </Stack>
              </Stack>
              :
              <>
                <div className='webcam-wrapper-ID'>
                  <Webcam
                    ref={webcamIDRef}
                    audio={false}
                    width={399}
                    height={317}
                    screenshotFormat="image/jpeg"
                  />
                </div>
                <Stack gap={2}>
                  <h2>Coloca la credencial en el rectángulo blanco.</h2>
                  <Button variant='contained' size='large' onClick={captureIdentification}>
                    Capturar
                  </Button>
                </Stack>
              </>
            }
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}


export default IDDialog