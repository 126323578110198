import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'

import { BorderLinearProgress } from "../../common/utilities/customMaterialUI"
import ParsoIco from "../../common/components/Svgs/ParsoIco"
import { api } from '../../api/api'
import { Types } from '../../state/actionTypes'
import { _dispatch as dispatch, defaultCatch, defaultSuccess } from '../../state/actions'
import './LoadPage.sass'

function LoadPage(props) {
  const {
    dispatch,
    defaultCatch,
    defaultSuccess
  } = props

  const navigate = useNavigate()

  const fetchCompany = () => {
    return api.get('/companies')
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        dispatch({
          type: Types.SET_COMPANIES,
          payload: data.companies
        })
      })
      .catch(defaultCatch)
  }

  const fetchBuildings = () => {
    return api.get('/buildings')
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        dispatch({
          type: Types.SET_BUILDINGS,
          payload: data.buildings
        })
      })
      .catch(defaultCatch)
  }

  useEffect(() => {
    Promise.all([fetchCompany(), fetchBuildings()]).then(() => {
      navigate('/')
    }).catch(error => console.error("Error fetching data", error))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="LoadPage">
      <div className="box-loading">
        <ParsoIco width={75} />
        <BorderLinearProgress />
      </div>
    </div>
  )
}

const mapDispatchToProps = {
  dispatch,
  defaultSuccess,
  defaultCatch
}

export default connect(null, mapDispatchToProps)(LoadPage)
